import React, { useState, useEffect } from "react";
import { RecoilRoot } from "recoil";
import MyRouter from "routers/index";
import { loadTranslations } from "i18n";

function App() {
  const [isLoaded, setIsLoaded] = useState(false);
  const loadData = async () => {
    await loadTranslations();
    setIsLoaded(true);
  };
  useEffect(() => {
    if (!isLoaded) loadData();
  }, []);
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <RecoilRoot>{isLoaded ? <MyRouter /> : <div>Loading...</div>}</RecoilRoot>
    </div>
  );
}

export default App;
