import { getBaseUrl, getDatabase } from '../rpc';

let APIResolver = () => Promise;

/** The will return 400 if you pass more than this many requests */
// resolve a single batch request

const resolveRequest = ([headers, body]) =>
  fetch(getBaseUrl() + '/web_api', {
    method: 'POST',
    headers,
    body,
    //credentials: 'same-origin',
  }).then(res => res.json());

// resolve multiple batch requests and merge results
//

const genCall = keys => {
  let vals = []
  keys.forEach(key => {
    let [model, method, args, opts] = key;
    let params = [model, method];
    params.push(args || []);
    params.push(opts || {});
    params.push([]);

    let data = JSON.stringify({
      method: 'execute',
      params: params,
      //id: new Date().getTime(),
    });
    let _headers = {
      'Content-type': 'application/json;charset=UTF-8'
    };
    if (getDatabase()) {
      _headers['X-Database'] = getDatabase();
    }
    // let locale = 'en_US';
    // _headers['X-Locale'] = locale;
    vals.push([_headers, data])
  })
  return vals
}

const resolveRpc = callers => {
  if (!(callers && callers.length > 0)) {
    return Promise.resolve({});
  }
  if (callers.length === 1) {
    return resolveRequest(callers[0]);
  }
  return Promise.all(callers.map(resolveRequest)).then(results =>
    Object.assign({}, ...results.filter(x => !!x)),
  );
};

const APICaller = APIResolver = (callers) => resolveRpc(genCall(callers));

export default APICaller;
