/*var _base_url = (typeof window !== 'undefined' && window.isDebug) ? window.location.origin: null;*/
// var _base_url = "https://drvii.anastue.com"
var _base_url: string = `${process.env.REACT_APP_BASE_URL}`;
var _static_url: string = "";
var _database: string = `${process.env.REACT_APP_DATABASE_NAME}`;

export const setStaticUrl = function (url: string) {
  _static_url = url;
};

export const setBaseUrl = function (url: string) {
  _base_url = url;
};

export const getBaseUrl = function () {
  return _base_url;
};


export const getDatabase = function () {
  return _database;
};

export const setDatabase = function (dbname: string) {
  if (dbname) {
    _database = dbname;
  }
};

export const getResizeFile = (fname: string, size?: string | number) => {
  if (!fname) return;
  if (size == "small") size = 128;
  else if (size == "medium") size = 256;
  else if (size == "large") size = 512;
  const re = /^(.*),(.*?)(\..*)?$/;
  let m = re.exec(fname);
  let new_fname = fname;
  if (m) {
    const s = m[1]; // fname
    const c = m[2]; // random
    const ext = m[3]; //Extension
    new_fname = s + "-resize-" + size + "," + c + ext;
  }
  return new_fname;
};

export const getFileUri = (filename?: string | null, resize?: string):string => {
  if (!filename) return "";
  if (resize) {
    filename = getResizeFile(filename, resize);
  }
  var url;
  if (filename && filename[0] == "/") {
    url = (_static_url || _base_url) + "/static" + filename;
  } else {
    url =
      (_static_url || _base_url) +
      "/static/db/" +
      _database +
      "/files/" +
      filename;
  }
  return url;
};
