import React, { Suspense, lazy } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
// import LoadingIcon from "components/LoadingIcon/LoadingIcon";
// import PageHome from "containers/PageHome/PageHome";
const PageHome = lazy(() => import("containers/PageHome/PageHome"));
// import PageTransfer from "containers/PageTransfer/PageTransfer";
const PageTransfer = lazy(() => import("containers/PageTransfer/PageTransfer"));
// import PageReview from "containers/PageReview/PageReview";
const PageReview = lazy(() => import("containers/PageReview/PageReview"));
// import PageCharterVan from "containers/PageCharterVan/PageCharterVan";
const PageCharterVan = lazy(
  () => import("containers/PageCharterVan/PageCharterVan")
);
// import PageTour from "containers/PageTour/PageTour";
// const PageTour = lazy(() => import("containers/PageTour/PageTour"));
// import PageTourDetail from "containers/PageTourDetail/PageTourDetail";
// const PageTourDetail = lazy(
//   () => import("containers/PageTourDetail/PageTourDetail")
// );
// import PageContact from "containers/PageContact/PageContact";
const PageContact = lazy(() => import("containers/PageContact/PageContact"));
// import PageAbout from "containers/PageAbout/PageAbout";
const PageAbout = lazy(() => import("containers/PageAbout/PageAbout"));
// import Page404 from "containers/Page404/Page404";
const Page404 = lazy(() => import("containers/Page404/Page404"));
// import SiteHeader from "containers/SiteHeader";
const SiteHeader = lazy(() => import("containers/SiteHeader"));

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome },
  { path: "/transfer", component: PageTransfer },
  { path: "/charter-van", component: PageCharterVan },
  // { path: "/tour", exact: true, component: PageTour },
  // { path: "/tour/:code", component: PageTourDetail },
  // { path: "/service", exact: true, component: PageTour },
  // { path: "/service/:code", component: PageTourDetail },
  { path: "/review", component: PageReview },
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
];

const Routes = () => {
  return (
    <BrowserRouter>
      <Suspense fallback={<></>}>
        {/* <BrowserRouter basename="/chisfis"> */}
        <ScrollToTop />
        <SiteHeader />
        <Helmet>
          <title>NPP Phuket Van Tour</title>
        </Helmet>
        <Switch>
          {pages.map(({ component, path, exact }) => {
            return (
              <Route
                key={path}
                component={component}
                exact={!!exact}
                path={path}
              />
            );
          })}
          <Route component={Page404} />
        </Switch>
        <Footer />
      </Suspense>
    </BrowserRouter>
  );
};

export default Routes;
