export interface SocialType {
  name: string;
  icon: string;
  href: string;
  color?: string;
}
export const socials: SocialType[] = [
  {
    name: "Facebook",
    // icon: "lab la-facebook-square",
    icon: "fab fa-facebook-square",
    href: "https://facebook.com/K.Eak5104",
    color: "#4267B2",
  },
  {
    name: "Line",
    icon: "fab fa-line",
    href: "https://page.line.me",
    color: "#00c300",
  },
  // { name: "Twitter", icon: "lab la-twitter", href: "#" },
  {
    name: "Youtube",
    // icon: "lab la-youtube",
    icon: "fab fa-youtube",
    href: "https://www.youtube.com/channel/UCnIMWcSZZfW2NZSDzUwTWHA",
    color: "#FF0000",
  },
  // { name: "Instagram", icon: "lab la-instagram", href: "#" },
  {
    name: "Whatsapp",
    icon: "fab fa-whatsapp",
    href: "https://wa.me/66980136154",
    color: "#25D366",
  },
  {
    name: "Phone",
    // icon: "fas fa-phone",
    icon: "fas fa-square-phone",
    href: "tel:66980136154",
    color: "#25D366",
  },
];
