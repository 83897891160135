// import { SocialType } from "shared/SocialsShare/SocialsShare";
import { SocialType, socials } from "data/socials";
import React, { FC } from "react";

export interface SocialsList1Props {
  className?: string;
}

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5" }) => {
  const renderItem = (item: SocialType, index: number) => {
    let style = {};
    if (item.color) {
      style = { ...style, color: item.color };
    }
    return (
      <a
        href={item.href}
        className='flex items-center text-2xl text-neutral-700 hover:text-black dark:text-neutral-300 dark:hover:text-white leading-none space-x-2 group'
        key={index}
      >
        <i className={item.icon} style={style}></i>
        <span className='hidden lg:block text-sm'>{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id='SocialsList1'>
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
