import React from "react";
import { Link } from "react-router-dom";
import logoImg from "images/logo.png";
import logoLightImg from "images/logo-light.png";
import LogoSvgLight from "./LogoSvgLight";
import LogoSvg from "./LogoSvg";
// import MainLogo from "images/logo.jpg";
// import MainLogo from "images/logo.png";
// import MainLogoDark from "images/logo_dark.png";

import MainLogo from "images/main_logos/logo_1.png";
import MainLogoDark from "images/main_logos/logo_1_dark.png";
// import MainLogoDark from "images/main_logos/logo_1.png";

export interface LogoProps {
  img?: string;
  imgLight?: string;
  className?: string;
  onClick?: () => void;
}

const Logo: React.FC<LogoProps> = ({
  img = MainLogo,
  imgLight = MainLogo,
  onClick,
  // img = logoImg,
  // imgLight = logoLightImg,
  className = "",
}) => {
  return (
    <Link
      to='/'
      className={`ttnc-logo inline-block text-primary-6000 ${className}`}
    >
      {/* <LogoSvgLight />
      <LogoSvg /> */}

      {/* THIS USE FOR MY CLIENT */}
      {/* PLEASE UN COMMENT BELLOW CODE AND USE IT */}
      <img
        className={`block max-h-16 ${imgLight ? "dark:hidden" : ""}`}
        src={img}
        alt='Logo'
        onClick={(e) => onClick?.()}
      />
      <img
        className='hidden max-h-16 dark:block'
        src={MainLogoDark}
        alt='Logo-Light'
        onClick={(e) => onClick?.()}
      />
    </Link>
  );
};

export default Logo;
