import React from "react";
import _ from "lodash"

export interface KeyStringProps {
  [key: string]: string;
}
export const renderHtml = (tag: string = "div", raw_html: string = "") =>
  React.createElement(tag, { dangerouslySetInnerHTML: { __html: raw_html } });

export const getCookies = () => {
  let data: KeyStringProps = {};
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    let ind = c.indexOf("=");
    let name = c.substring(0, ind);
    let val = c.substring(ind + 1, c.length);
    data[name] = decodeURIComponent(val);
  }
  return data;
};

export const getCookie = (name: string) => {
  const cookies = getCookies();
  return cookies[name];
};

export const setCookie = (
  sName: string,
  sValue: string,
  days?: number | undefined | null
) => {
  const today = new Date();
  let expire = new Date();
  if (days == null || days == 0) days = 7;
  expire.setTime(today.getTime() + 3600000 * 24 * days);
  const sCookie =
    encodeURIComponent(sName) +
    "=" +
    encodeURIComponent(sValue) +
    ";expires=" +
    expire.toUTCString() +
    ";path=/";
  document.cookie = sCookie;
};

export const setCookies = (data: KeyStringProps, d: number | undefined | null) => {
  for (let n in data) {
    let v = data[n];
    if (v) {
      let val;
      let days: number | undefined | null;
      if (_.isArray(v)) {
        val = v[0];
        days = parseInt(v[1]);
      } else {
        val = v;
        days = d || null;
      }
      setCookie(n, val, days);
    } else {
      clearCookie(n);
    }
  }
};

export const clearCookie = (sName: string) => {
  setCookie(sName, "");
};

export const clearCookies = () => {
  let cookies = getCookies();
  for (let n in cookies) {
    clearCookie(n);
  }
};
