import { useCallback } from "react";
import { useRecoilValue } from "recoil";
import { langState } from "store";
import api from "api";
import lz from "utils/lzString";
import * as _translationData from "i18n/data.json";

const translationData: { [key: string]: any } = _translationData;
var _translations: any = {};

export const setTranslations = (data: any) => {
  _translations = data;
};

export function useTranslation() {
  const lang = useRecoilValue(langState);
  const t = (text: string) => {
    if (lang === "en_US") return text;
    let intTranslate = translationData?.[text]?.[lang];
    if (!!intTranslate) return intTranslate;
    let data = _translations?.data;
    return data?.[lang]?.[text] || text;
  };
  return useCallback(t, [lang]);
}

export function useActiveLang() {
  const lang = useRecoilValue(langState);
  return lang;
}

export default useTranslation;

export const loadTranslations = async () => {
  const rq = ["translation", "get_hash"];
  const { result: hash } = await api([rq]);
  let _translations;
  try {
    const compressed = localStorage.getItem("_translations");
    const decompressed = lz.decompressFromUTF16(compressed);
    _translations = JSON.parse(decompressed || "");
  } catch (e) {
    console.warn(e);
  }
  setTimeout(
    console.log.bind(
      console,
      "%cNPP Phuket Van Tour",
      "color: #fff; background: #5046e5; font-size: 20px; padding: 10px;"
    ),
    0
  );
  setTimeout(
    console.log.bind(
      console,
      "%cdeveloped by https://anastue.com",
      "color: #000; background: #dbeafe; font-size: 12px; padding: 10px;"
    ),
    0
  );

  if (_translations?.hash !== hash) {
    const rq2 = ["translation", "get_translations"];
    const { result } = await api([rq2]);
    _translations = {
      hash,
      data: result
    };
    const compressed = lz.compressToUTF16(JSON.stringify(_translations));
    localStorage.setItem("_translations", compressed);
  }
  setTranslations(_translations);
};
